import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";

import { PtPost } from "../types/Post";
import { ScAuthor } from "../templates/styled";
import removeMd from "remove-markdown";

const ScMain = styled.div`
  margin-bottom: 4rem;

  @media screen and (min-width: 992px) {
    margin-bottom: 110px;
  }
`;

const ScPanelCover = styled.div``;

const ScFeatureImg = styled.div``;
const ScFeature = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  text-align: center;
  height: 220px;
  overflow: hidden;
  position: relative;

  ${ScFeatureImg} {
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (min-width: 992px) {
    height: 550px;
  }
`;

const ScPanelContent = styled.div`
  margin-top: 2rem;

  @media screen and (min-width: 992px) {
    margin-top: 36px;
    width: 640px;
  }
`;

const ScTag = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4;
  color: #868f97;
`;

const ScTitle = styled.h2`
  font-size: 24px;
  line-height: 1.2;

  a {
    color: white;

    &:hover {
      color: white;
    }
  }

  @media screen and (min-width: 992px) {
    font-size: 40px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

const ScOverview = styled.p`
  margin-bottom: 0.5em;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);

  @media screen and (min-width: 992px) {
    margin-bottom: 30px;
  }
`;

export const BigCard = ({ post }: { post: PtPost }) => {
  return (
    <ScMain>
      <ScPanelCover>
        <ScFeature>
          <Link to={`/blog/${post.slug}`}>
            <ScFeatureImg>
              <Img fluid={post.featuredImg.childImageSharp.fluid} />
            </ScFeatureImg>
          </Link>
        </ScFeature>
      </ScPanelCover>

      <ScPanelContent>
        <ScTag>{post.category}</ScTag>
        <ScTitle>
          <Link to={`/blog/${post.slug}`}>{removeMd(post.title)}</Link>
        </ScTitle>
        <ScOverview>{post.summary}</ScOverview>
        <ScAuthor>
          <img src="/images/v5/blog/blog-pt-icon.png" alt="PowerTrade Team" />
          <main>{post.author}</main>
          {post.date ? <span>{post.date}</span> : null}
        </ScAuthor>
      </ScPanelContent>
    </ScMain>
  );
};
