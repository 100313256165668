import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { BigCard } from "../components/BigCard";
import { Card } from "../components/Card";
import { Container as ContainerOrg } from "../components/Grid";
import { PtPost } from "../types/Post";
import { Layout } from "../components/Layout";

const ScRoot = styled.div`
  background-color: var(--darkmode);
  padding-top: 1px;
`;

const ScContainer = styled(ContainerOrg)`
  max-width: 984px;
`;

const ScMain = styled.div`
  margin-top: 3rem;
  margin-bottom: 5rem;
  @media screen and (min-width: 992px) {
    margin-top: 3rem;
    margin-bottom: 10rem;
  }
`;

const ScHeader = styled.h2`
  margin-top: 6rem;
  display: flex;
  align-items: center;
  main {
    font-weight: bold;
    border-right: 1px solid #868f97;
    margin-right: 20px;
    padding-right: 20px;
  }
  aside {
    font-weight: normal;
    font-size: 14px;
    color: #868f97;
  }
  @media screen and (min-width: 992px) {
    margin-top: 56px;
    margin-bottom: 56px;
  }
`;

const ScPostList = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -24px;
    margin-right: -24px;
  }
`;

const BlogTemplate = ({ pageContext }: any) => {
  const posts: PtPost[] = pageContext.posts;
  const firstItem = posts[0];

  return (
    <Layout>
      <ScRoot>
        <Helmet titleTemplate="%s">
          <title>PowerTrade Blog</title>
        </Helmet>

        <ScContainer>
          <ScHeader>
            <main>Blog</main>
            <aside>Updates from the PowerTrade team</aside>
          </ScHeader>

          <ScMain>
            <BigCard post={firstItem} />

            <ScPostList>
              {posts.slice(1).map((i) => (
                <Card key={i.id} post={i} />
              ))}
            </ScPostList>
          </ScMain>
        </ScContainer>
      </ScRoot>
    </Layout>
  );
};

export default BlogTemplate;
